import React from 'react'
import Helmet from 'react-helmet'

const SaleTransactionCustomerVerification = ({ txn }) => {
  return (
    <div className='verification-required'>
      <Helmet>
        <title>Customer verification: Cosmonaut Merchant Demonstrator</title>
      </Helmet>
      {txn && txn.cust_verification_required && txn.cust_verification_required.render_html && (
        <HtmlVerification html={txn.cust_verification_required.render_html} />
      )}
      {txn && txn.cust_verification_required && txn.cust_verification_required.visit_url && (
        <URLVerification url={txn.cust_verification_required.visit_url} />
      )}
    </div>
  )
}

const HtmlVerification = ({ html }) => (
  <div className='render_html'>
    <p className='explanation'>The upstream PSP requires HTML customer verification, for example 3D-Secure:</p>
    <iframe name='merchant-iframe' className='verification' srcDoc={html} />
  </div>
)

const URLVerification = ({ url }) => (
  <div className='visit_url'>
    <p className='explanation'>The upstream PSP requires URL customer verification, for example 3D-Secure:</p>
    <iframe name='merchant-iframe' className='verification' src={url} />
  </div>
)
export default SaleTransactionCustomerVerification
